import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { getDeviceType } from '@/core/utils';

import {
	LazyLoadHomeJP,
	LazyLoadSuccessLogin,
	LazyLoadLoginJP,
	LazyLoadNotification,
	LazyLoadLoginReview,
	LazyLoadLoginInactive,
	LazyLoadProductsJp,
	LazyLoadMyFavoriteJp,
	LazyLoadServiceJp,
	LazyLoadHelpJp,
	LazyLoadSearchResult,
	LazyLoadMyProfileJp,
	LazyAdminLayout,
	LazyContactUsJp,
	LazyLoadAddtionalJp,
	LazyLoadError404,
	LazyLoadFAQ,
	LazyLoadShowFile,
	LazyFormInquiryFTIR,
	LazyFormInquiryUVVis,
	LazyMobileLogin,
	LazyMobileLoginInactive,
	LazyMobileLoginReview,
	LazyMobileAddtionalInfoJp,
	LazyMobileAddtionalHavebranch,
	LazyMobileAddtionalNotbranch,
} from '../pages';

// Auth guard for the routes that requires permissions
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const auth = useContext(AuthContext);
	const location = useLocation();
	if (!auth.isAuthenticated()) {
		return <Navigate to='/home' state={{ from: location }} replace />;
	}

	return children;
};

interface Route {
	path: string;
	element: React.ReactElement;
}

type Routes = Route[];

const mobileRoutes: Record<string, React.ReactElement> = {
	'/': <LazyMobileLogin />,
	'/login-review': <LazyMobileLoginReview />,
	'/login-inactive': <LazyMobileLoginInactive />,
	'/addtional-info': <LazyMobileAddtionalInfoJp />,
	'/addtional-havebranch': <LazyMobileAddtionalHavebranch />,
	'/addtional-notbranch': <LazyMobileAddtionalNotbranch />,
};

const updateRoutesForDevice = (routes: Routes): Routes => {
	const deviceType = getDeviceType();
	if (deviceType === 'mobile') {
		return routes.map((route: any) => ({
			...route,
			element: mobileRoutes[route.path] || route.element,
		}));
	}
	return routes;
};

export const ApplayoutRoutes = [
	{
		path: '/home',
		element: <LazyLoadHomeJP />,
	},
	{
		path: '/notification',
		element: <LazyLoadNotification />,
		children: [
			{
				path: ':param1/:param2',
				element: <LazyLoadNotification />,
			},
			{
				path: ':param1',
				element: <LazyLoadNotification />,
			},
		],
	},
	{
		path: '/products',
		element: <LazyLoadProductsJp />,
		children: [
			{
				path: ':param1/:param2/:param3',
				element: <LazyLoadProductsJp />,
			},
			{
				path: ':param1/:param2',
				element: <LazyLoadProductsJp />,
			},
			{
				path: ':param1',
				element: <LazyLoadProductsJp />,
			},
		],
	},
	{
		path: '/service',
		element: <LazyLoadServiceJp />,
		children: [
			{
				path: ':param1/:param2/:param3',
				element: <LazyLoadServiceJp />,
			},
			{
				path: ':param1/:param2',
				element: <LazyLoadServiceJp />,
			},
			{
				path: ':param1',
				element: <LazyLoadServiceJp />,
			},
		],
	},
	{
		path: '/help',
		element: <LazyLoadHelpJp />,
		children: [
			{
				path: ':param1/:param2',
				element: <LazyLoadHelpJp />,
			},
			{
				path: ':param1',
				element: <LazyLoadHelpJp />,
			},
		],
	},
	{
		path: '/my-favorite',
		element: <LazyLoadMyFavoriteJp />,
	},
	{
		path: '/searchResult',
		element: <LazyLoadSearchResult />,
		children: [
			{
				path: ':param1/:param2',
				element: <LazyLoadSearchResult />,
			},
			{
				path: ':param1',
				element: <LazyLoadSearchResult />,
			},
		],
	},
	{
		path: '/my-profile',
		element: <LazyLoadMyProfileJp />,
	},
	{
		path: '/admin',
		children: [
			{
				path: ':param1/:param2',
				element: <LazyAdminLayout />,
			},
			{
				path: ':param1',
				element: <LazyAdminLayout />,
			},
		],
		element: <LazyAdminLayout />,
	},
	{
		path: '/contact-us',
		element: <LazyContactUsJp />,
	},
];

const PcPublicRoutes: Routes = [
	{
		path: '/login/callback',
		element: <LazyLoadSuccessLogin />,
	},
	{
		path: '/',
		element: <LazyLoadLoginJP />,
	},
	{
		path: '/login-review',
		element: <LazyLoadLoginReview />,
	},
	{
		path: '/login-inactive',
		element: <LazyLoadLoginInactive />,
	},
	{
		path: '/addtional-info',
		element: <LazyLoadAddtionalJp />,
	},
	{
		path: '/404',
		element: <LazyLoadError404 />,
	},
	{
		path: '*',
		element: <LazyLoadError404 />,
	},
	{
		path: '/FAQ',
		element: <LazyLoadFAQ />,
	},
	{
		path: '/show-file',
		element: <LazyLoadShowFile />,
	},
	{
		path: '/form-inquiry-FTIR',
		element: <LazyFormInquiryFTIR />,
	},
	{
		path: '/form-inquiry-UVVIS',
		element: <LazyFormInquiryUVVis />,
	},
	{
		path: '/addtional-havebranch',
		element: <LazyMobileAddtionalHavebranch />,
	},
	{
		path: '/addtional-notbranch',
		element: <LazyMobileAddtionalNotbranch />,
	},
];

export const PublicRoutes = updateRoutesForDevice(PcPublicRoutes);
