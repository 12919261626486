import { http, ResponseBaseType } from '@/core/services/http.service';

class LoginService {
	putLoginCode = async (data: any) => {
		const response = await http.post<any>('/auth/verifyCode', data);
		return response;
	};

	refreshToken = async (data: any) => {
		const response = await http.post<any>('/auth/refresh/token', data);
		return response;
	};

	getNews = async () => {
		const response = await http.get<ResponseBaseType<any>>('/');
		return response;
	};

	private token = 'token';

	getTokenData = () => {
		return localStorage.getItem(this.token) || '';
	};
	setTokenData = (item: string) => {
		return localStorage.setItem(this.token, item);
	};

	removeTokenData = () => {
		return localStorage.removeItem(this.token);
	};

	private tokenType = 'tokenType';

	getTokenType = () => {
		return localStorage.getItem(this.tokenType);
	};

	setTokenType = (item: string) => {
		return localStorage.setItem(this.tokenType, item);
	};

	removeTokenType = () => {
		return localStorage.removeItem(this.tokenType);
	};

	private loginSkipUrl = 'loginSkipUrl';

	getLoginSkipUrl = () => {
		return localStorage.getItem(this.loginSkipUrl) || '';
	};

	setLoginSkipUrl = (item: string) => {
		return localStorage.setItem(this.loginSkipUrl, item);
	};

	private forwardDirect = 'forwardDirect';

	getForwardDirect = () => {
		return localStorage.getItem(this.forwardDirect) || '';
	};

	setForwardDirect = (item: string) => {
		return localStorage.setItem(this.forwardDirect, item);
	};

	private gigyaToken = 'gigyaToken';

	getGigyaToken = () => {
		return localStorage.getItem(this.gigyaToken) || '';
	};

	setGigyaToken = (item: string) => {
		return localStorage.setItem(this.gigyaToken, item);
	};

	removeGigyaToken = () => {
		return localStorage.removeItem(this.gigyaToken);
	};

	private notificationEmailMark = 'email';

	getNotificationEmailMark = () => {
		return localStorage.getItem(this.notificationEmailMark) || '';
	};

	setNotificationEmailMark = (item: string) => {
		return localStorage.setItem(this.notificationEmailMark, item);
	};

	removeNotificationEmailMark = () => {
		return localStorage.removeItem(this.notificationEmailMark);
	};

	private creatUserEmail = 'loginEmail';

	getCreatUserEmail = () => {
		return localStorage.getItem(this.creatUserEmail) || '';
	};

	setCreatUserEmail = (item: string) => {
		return localStorage.setItem(this.creatUserEmail, item);
	};

	removeCreatUserEmail = () => {
		return localStorage.removeItem(this.creatUserEmail);
	};
}

export const myLoginService = new LoginService();
